:root {
	--accent: green;
}
.App {
	text-align: left;
	padding: 2rem;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.inputSection {
	background-color: black;
	border: none;
	color: var(--accent);
}

.inputSection > p {
	margin: 0.5rem 0rem;
}

.inputTerminal {
	border: none;
	color: var(--accent);
	background-color: transparent;
	width: 90%;
}

.inputTerminal:focus-visible {
	border: none !important;
	border-color: transparent;
}